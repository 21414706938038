<template>
  <q-page padding>
    <div class="page-header">
      <q-toolbar-title class="text-5 q-mr-auto q-ma-sm">Mailling</q-toolbar-title>
      <q-breadcrumbs class="q-mr-auto q-ma-sm">
        <q-breadcrumbs-el label="Painel de Controle" to="/ " />
        <q-breadcrumbs-el label="Mailling" exact />
      </q-breadcrumbs>
    </div>
    <!--  <q-separator spaced /> -->
    <form-mailing />
  </q-page>
</template>

<script>
import FormMailing from '../../components/affiliate/FormMailing.vue';

export default {
  components: { FormMailing },
  name: 'Mailing'
};
</script>

<template>
  <q-card flat bordered>
    <q-dialog v-model="viewHtml" full-width full-height>
      <q-card>
        <q-card-actions align="right">
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-actions>
        <q-card-section class="q-pt-none" v-html="html" />
      </q-card>
    </q-dialog>

    <q-tabs
      v-model="tab"
      dense
      className="text-grey"
      active-color="primary"
      indicator-color="primary"
      align="justify"
      narrow-indicator
    >
      <q-tab name="registration" label="Cadastro" />
      <q-tab name="listing" label="Listagem" />
    </q-tabs>

    <q-separator />

    <q-tab-panels v-model="tab" animated>
      <q-tab-panel name="registration">
        <mailing-content-registration
          @viewMail="previewMail"
          :campaignList="campaignList"
          @tabListing="tab = 'listing'"
        />
      </q-tab-panel>

      <q-tab-panel name="listing">
        <mailing-content-listing
          @tabRegistration="tab = 'registration'"
          @viewMail="previewMail"
          :campaignList="campaignList"
          :channelList="channelList"
        />
      </q-tab-panel>
    </q-tab-panels>
  </q-card>
</template>

<script>
import MailingContentRegistration from './mailingContent/MailingContentRegistration';
import MailingContentListing from './mailingContent/MailingContentListing';

export default {
  name: 'FormMailing',

  components: {
    MailingContentRegistration,
    MailingContentListing
  },

  data() {
    return {
      tab: 'registration',
      campaignList: [],
      channelList: [],

      viewHtml: false,
      html: null
    };
  },

  created() {
    this.getCampaigns();
    this.getChannels();
  },

  methods: {
    previewMail(e) {
      this.html = e;
      this.viewHtml = true;
    },

    async getCampaigns() {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get(`redirect?app=ADVERTISER&path=/api/get/campaign/all`);
        if (status === 200) {
          this.campaignList = [{ id: 0, name: 'TODOS AFILIADOS' }, ...data];
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getChannels() {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get(`/redirect?app=AFFILIATE&path=/api/affiliate/site/filter`);
        if (status === 200) this.channelList = data;
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  }
};
</script>

<template>
  <div>
    <mailing-content-form
      :campaignList="campaignList"
      :channelsSelected="channelsSelected"
      :formModal="formModal"
      :propsMailing="propsMailing"
      @close="formModal = false"
      @setChannelsSelected="channelsSelected = $event"
    />

    <q-dialog v-model="confirmSendMail" persistent>
      <q-card>
        <q-card-section>
          <div class="text-h6">Confirma o envio do Mailing {{ idChosen }}?</div>
        </q-card-section>
        <q-card-actions align="center">
          <q-btn class="q-px-md" no-caps outline label="Cancelar" color="primary" v-close-popup />
          <q-btn
            class="q-px-md"
            no-caps
            label="Confirmar"
            color="primary"
            @click="sendMailing(idChosen)"
            v-close-popup
          />
        </q-card-actions>
        <q-card-section class="q-pt-none" />
      </q-card>
    </q-dialog>

    <q-toolbar class="q-pr-none q-mb-md">
      <q-space />
      <q-btn
        outline
        icon="mdi-filter-outline"
        color="primary"
        no-caps
        class="q-px-sm q-mx-sm"
        label="Filtrar"
        @click="filter = !filter"
      >
        <q-tooltip> Filtrar Campanhas </q-tooltip></q-btn
      >

      <q-btn
        unelevated
        color="primary"
        class="q-px-lg"
        icon="mdi-plus"
        label="Adicionar"
        no-caps
        @click="() => this.$emit('tabRegistration')"
      />
    </q-toolbar>
    <q-card flat v-if="filter" class="q-mb-sm">
      <q-card-section class="q-pt-none row q-col-gutter-md">
        <div class="col-2">
          <q-input
            outlined
            label="ID da Campanha"
            :disable="disable_idCampaing"
            v-model="params.idCampaign"
            type="text"
          />
        </div>
        <div class="col-4">
          <q-select
            v-model="params.campaignSelected"
            :options="params.campaignsFiltered"
            label="Campanha"
            outlined
            option-label="name"
            option-value="id"
            clear-icon="mdi-close"
            clearable
            use-input
            input-debounce="0"
            @filter="filterCampaigns"
            @input="updateCampaingSelect"
          />
        </div>
        <div class="col-6">
          <q-select
            v-model="params.channelSelected"
            :options="params.channelsFiltered"
            label="Canal"
            outlined
            option-label="name"
            option-value="id"
            clear-icon="mdi-close"
            clearable
            use-input
            input-debounce="0"
            @filter="filterChannel"
          />
        </div>

        <div class="col-6">
          <q-input outlined label="Assunto" v-model="params.subject" type="text" />
        </div>

        <div class="col-6">
          <q-card flat>
            <q-card-section class="q-pa-none">
              <span class="text-weight-medium">Status</span>
              <div class="q-gutter-sm">
                <q-checkbox v-model="params.status" val="1" label="Novo" />
                <q-checkbox v-model="params.status" val="2" label="Enviado" />
                <q-checkbox v-model="params.status" val="3" label="Erro ao Enviar" />
                <q-checkbox v-model="params.status" val="4" label="Cancelado" />
                <q-checkbox v-model="params.status" :val="'-1' || '0'" label="Enviando" />
              </div>
            </q-card-section>
          </q-card>
        </div>

        <div class="col-12 text-right">
          <q-btn color="primary" :label="$t('clear')" no-caps outline class="q-px-md q-mx-md" @click="clearFilter" />
          <q-btn color="positive" :label="$t('search')" no-caps unelevated class="q-px-md" @click="onListMailing()" />
        </div>
      </q-card-section>
    </q-card>

    <div>
      <q-table
        flat
        bordered
        :data="listMailing.data"
        row-key="id"
        :columns="columns"
        :selected.sync="selected"
        class="mailing"
        :pagination.sync="pagination"
      >
        <template v-slot:body-cell-created_at="props">
          <q-td :props="props">
            {{ $moment(props.value).format('LL') }}
          </q-td>
        </template>
        <template v-slot:body-cell-send_data="props">
          <q-td :props="props">
            {{ $moment(props.value).format('LL') }}
          </q-td>
        </template>

        <template v-slot:body-cell-status="props">
          <q-td :props="props">
            <q-chip
              dense
              text-color="white"
              :color="
                props.row.status == 1
                  ? 'primary'
                  : props.row.status == 2
                  ? 'positive'
                  : props.row.status == 3
                  ? 'negative'
                  : props.row.status == 4
                  ? 'orange'
                  : 'info'
              "
              :label="labelStatus(props.row.status)"
            />
          </q-td>
        </template>

        <template v-slot:body-cell-mailing_type_id="props">
         <q-td :props="props">
          {{ getMailingTypeName(props.row.mailing_type_id) }}
         </q-td>
        </template>

        <template v-slot:body-cell-action="props">
          <q-td :props="props">
            <q-btn
              unelevated
              round
              flat
              icon="mdi-eye-outline"
              color="primary"
              @click="() => $emit('viewMail', props.row.content)"
            >
              <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px"> Preview </q-tooltip>
            </q-btn>
            <q-btn
              unelevated
              round
              flat
              icon="mdi-pencil-outline"
              color="primary"
              @click="(formModal = true), (propsMailing = props.row), getChannelsById(props.row.campaign_id)"
              :disable="[-1, 0, 2, 4].includes(props.row.status)"
            >
              <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                {{ $t('edit') }}
              </q-tooltip>
            </q-btn>

            <q-btn unelevated round flat icon="mdi-email-sync-outline" color="primary">
              <q-popup-edit
                :cover="false"
                :offset="[200, -200]"
                title="Confirma email teste?"
                v-model="mailTest"
                :ref="'popupTest' + props.row.id"
                @before-show="selected.push(listMailing.find((item) => item.id == props.row.id))"
                @hide="selected = []"
              >
                <template v-slot="scope">
                  <q-input
                    outlined
                    autofocus
                    dense
                    v-model="mailTest"
                    placeholder="Digite email destino"
                    :rules="[isEmail]"
                    lazy-rules
                  >
                  </q-input>
                  <q-btn class="q-px-sm" no-caps outline dense label="Cancelar" @click.stop="scope.cancel" />
                  <!-- @click="$refs['popupEdit' + props.row.id].hide()" -->

                  <q-btn
                    class="q-ml-lg q-px-lg"
                    no-caps
                    dense
                    label="Enviar"
                    color="primary"
                    @click="sendTest(props.row.id, mailTest)"
                    @click.stop="scope.cancel"
                    :disable="scope.validate(scope.value) === false || scope.initialValue === scope.value"
                  />
                </template>
              </q-popup-edit>

              <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                Enviar Teste
              </q-tooltip>
            </q-btn>

            <q-btn
              unelevated
              round
              flat
              icon="mdi-email-check-outline"
              color="primary"
              @click="(idChosen = props.row.id), (confirmSendMail = true)"
              :disable="[-1, 0, 2, 4].includes(props.row.status)"
            >
              <!-- :icon="props.row.id == '11' ? 'mdi-notebook-plus-outline' : 'mdi-notebook-check-outline'" -->
              <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px"> Enviar </q-tooltip>
            </q-btn>

            <q-btn
              unelevated
              round
              flat
              icon="mdi-trash-can-outline"
              color="negative"
              :disable="[-1, 0, 2, 4].includes(props.row.status)"
            >
              <q-popup-edit
                title="Confirma desativação?"
                :cover="false"
                :offset="[0, -140]"
                :ref="'popup' + props.row.id"
                @before-show="selected.push(listMailing.find((item) => item.id == props.row.id))"
                @hide="selected = []"
              >
                <q-btn
                  class="q-px-sm"
                  label="Cancelar"
                  no-caps
                  outline
                  dense
                  @click="$refs['popup' + props.row.id].hide()"
                />
                <q-btn
                  class="q-ml-lg q-px-sm"
                  style="font-weight: bold"
                  label="Confirmar"
                  color="negative"
                  no-caps
                  dense
                  @click="delMailing(props.row.id), $refs['popup' + props.row.id].hide()"
                />
              </q-popup-edit>

              <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px"> Desativar </q-tooltip>
            </q-btn>
          </q-td>
        </template>

        <template v-slot:bottom>
          <q-toolbar class="bg-transparent" :class="!$q.dark.isActive ? 'text-dark' : 'text-white'">
            <div class="row items-center">
            <span class="q-mr-md">Registros por página:</span>
                <q-select
                v-model="pagination.rowsPerPage"
                :options="[10, 20, 50, 100]"
                dense
                @input="onRowsPerPageChange"
                style="max-width: 60px; padding-left: 8px; padding-right: 8px"
                />
            <span>Total: {{ listMailing.total }}</span>
            </div>
            <q-space />
            <q-pagination
              unelevated
              v-model="currentPage"
              color="primary"
              :max="listMailing.last_page"
              :max-pages="6"
              boundary-numbers
              direction-links
            />
          </q-toolbar>
        </template>
      </q-table>
    </div>
  </div>
</template>

<script>
import CampaignMixin from '../../../mixins/CampaignMixin';
import MailingContentForm from './MailingContentForm';

export default {
  name: 'MailingContentListing',
  mixins: [CampaignMixin],
  components: { MailingContentForm },

  props: {
    campaignList: [],
    channelList: [],
    mailingTypesList: []
  },

  data() {
    return {
      channelsSelected: [],
      propsMailing: {},
      selected: [],
      formModal: false,
      idChosen: null,
      mailTest: '',
      confirmSendMail: false,
      viewHtml: false,
      filter: true,
      params: {
        campaignsFiltered: [],
        campaignSelected: null,
        channelsFiltered: [],
        channelSelected: null,

        email: null,
        subject: null,
        idCampaign: null,
        status: [],
        categoryId: null
      },

      listMailing: [],

      disable_idCampaing: false,

      columns: [
        {
          name: 'id',
          label: 'ID',
          align: 'left',
          field: 'id',
          required: true,
          sortable: true
        },
        {
          name: 'campaign_id',
          label: 'Campanha',
          align: 'left',
          field: (row) => {
            if (row.campaign_id) return this.campaignList.find((v) => v.id == row.campaign_id).name;
            else return '---';
          },
          required: true,
          sortable: true
        },
        {
          name: 'mailing_type_id',
          label: 'Tipo',
          align: 'left',
          field: 'mailing_type_id',
          required: true,
          sortable: true
        },
        {
          name: 'subject',
          label: 'Assunto',
          align: 'left',
          field: 'subject',
          required: true,
          sortable: true
        },
        {
          name: 'created_at',
          label: 'Criado em',
          align: 'left',
          field: 'created_at',
          required: true,
          sortable: true
        },
        {
          name: 'send_data',
          label: 'Enviado em',
          align: 'left',
          field: 'send_data',
          required: true,
          sortable: true
        },
        {
          name: 'status',
          label: 'Status',
          align: 'left',
          field: 'status',
          required: true,
          sortable: true
        },
        {
          name: 'action',
          align: 'center',
          label: 'Ações',
          required: true
        }
      ],

      pagination: {
        sortBy: 'created_at',
        descending: true,
        rowsPerPage: 10
      }
    };
  },

  watch: {
    currentPage() {
      if (this.listMailing.from != this.listMailing.current_page) {
        this.onListMailing(`page=${this.currentPage}`, `%26perPage=${this.pagination.rowsPerPage}`);
      }
    },

    propsMailing() {
      this.channelsSelected = [];
      let channelId = this.propsMailing.sites.split(',');
      let temp = [];
      setTimeout(() => {
        for (let i = 0; i < this.channels.length; i++) {
          temp.push(this.channels.filter((item) => item.site_id == channelId[i]));
          this.channelsSelected = this.channelsSelected.concat(temp[i]);
        }
      }, '500');
    }
  },

  computed: {
    currentPage: {
      get() {
        return this.listMailing.current_page;
      },
      set(value) {
        // this.ActionSetPagination({
        //   ...this.listMailing,
        //   current_page: value,
        // });
        this.listMailing.current_page = value;
      }
    }
  },

  created() {
    this.pagination.rowsPerPage = parseInt(localStorage.getItem('rowsPerPage')) || 10;
    this.onListMailing(null, '%26perPage=' + this.pagination.rowsPerPage);
    this.loadMailingTypes();
  },

  methods: {
    async delMailing(id) {
      this.onLoading(true);
      try {
        const { status } = await this.$http.delete(`redirect?app=AFFILIATE&path=/api/mailing/${id}`);

        if (status === 200 || status === 201) {
          const itemUpdate = this.listMailing.data.find((item) => item.id == id);
          itemUpdate.status = 4;
          Object.assign(this.listMailing.data, itemUpdate);
          this.successNotify('Mailing cancelado com sucesso!');
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async sendTest(id, mail) {
      this.onLoading(true);
      try {
        const { status } = await this.$http.post(`/v2/redirect`, {
          app: `affiliate`,
          path: `/api/mailing/sendTest`,
          params: {
            data: {
              emails: mail,
              id: `${id}`
            }
          }
        });

        if (status === 200 || status === 201) this.successNotify('Mailing TESTE enviado com sucesso!');
      } catch (error) {
        this.errorNotify('Email inválido!');
      } finally {
        this.onLoading(false);
      }
    },

    async sendMailing(id) {
      this.onLoading(true);
      try {
        const { status } = await this.$http.post(`/v2/redirect`, {
          app: `affiliate`,
          path: `/api/mailing/send`,
          params: {
            data: {
              id: `${id}`
            }
          }
        });

        if (status === 200 || status === 201) {
          const itemUpdate = this.listMailing.data.find((item) => item.id == id);
          itemUpdate.status = 0;
          Object.assign(this.listMailing.data, itemUpdate);
          this.successNotify('Mailing enviado com sucesso!');
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    labelStatus(status) {
      switch (status) {
        case -1:
        case 0:
          return 'Enviando';
        case 1:
          return 'Novo';
        case 2:
          return 'Enviado';
        case 3:
          return 'Erro';
        case 4:
          return 'Cancelado';
        default:
          return '';
      }
    },

    async onListMailing(page, perPage) {
      this.onLoading(true);
      let pagination;
      if (!page) pagination = 'page=1';
      else pagination = page;
      if (!perPage) pagination += `%26perPage=${localStorage.getItem('rowsPerPage') || '%26perPage=10'}`;
      else pagination += perPage;

      try {
        let query = [];
        let queryString = null;
        if (this.params.idCampaign != null) query.push(`campaign_id=${this.params.idCampaign}`);

        if (this.params.channelSelected != null) query.push(`site_id=${this.params.channelSelected.id}`);

        if (this.params.subject != null) query.push(`subject=${this.params.subject}`);

        if (this.params.status != '') query.push(`status=${this.params.status}`);

        if (query.length > 0) queryString = pagination + '%26' + query.join('%26');
        else queryString = pagination;

        const { data, status } = await this.$http.get(`redirect?app=AFFILIATE&path=/api/mailing%3F${queryString}`);
        if (status === 200) this.listMailing = data;
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    filterCampaigns(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        if (!needle) this.params.campaignsFiltered = this.campaignList;
        this.params.campaignsFiltered = this.campaignList.filter(
          (v) => v.name.toLocaleLowerCase().indexOf(needle) > -1
        );
      });
    },

    filterChannel(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        if (!needle) this.params.channelsFiltered = this.channelList;
        this.params.channelsFiltered = this.channelList.filter((v) => v.name.toLocaleLowerCase().indexOf(needle) > -1);
      });
    },
    updateCampaingSelect(value) {
      if (value) {
        this.disable_idCampaing = true;
        this.params.idCampaign = value.id;
      } else {
        this.params.idCampaign = null;
        this.disable_idCampaing = false;
      }
    },

    clearFilter() {
      for (let i in this.params) this.params[i] = Array.isArray(this.params[i]) ? [] : null;
      this.onListMailing();
    },

    async loadMailingTypes() {
    try {
      const response = await this.$http.get('redirect?app=AFFILIATE&path=/api/mailing-types');
      this.mailingTypesList = response.data;
    } catch (error) {
        console.error('Erro ao carregar tipos de mailing:', error);
    }
    },

    getMailingTypeName(mailing_type_id) {
      const mailingType = this.mailingTypesList.find(type => type.id === mailing_type_id);
      return mailingType ? mailingType.name : '';
    },

    onRowsPerPageChange(value) {
      this.pagination.rowsPerPage = value;
      localStorage.setItem('rowsPerPage', value);
      this.currentPage = 1;
      this.onListMailing(`page=1`, `%26perPage=${value}`);
    }

  }
};
</script>

<style>
.mailing tr.selected {
  background: #a5271e21;
}
</style>
